export default {
  "zh-TW": {
    "glossary__status__errored": "We're sorry, we're not able to retrieve this information at the moment, please try back later",
    "glossary__status__invalid__location": "Not a valid search location, try for a different location",
    "glossary__status__loading": "Loading...",
    "glossary__status__no_events": "No events found for the location, distance and filters specified",
    "pagination__next": "下一頁",
    "pagination__previous": "上一頁",
    "sort_selector__date": "日期",
    "sort_selector__distance": "Distance",
    "event_details": "賽事詳情",
    "event_details__add_to_calendar": "添加到日曆",
    "event_details__meta__description__fallback": "Details of your event",
    "event_details__meta__title": "{eventName}: {eventDate} at {organizationName}",
    "filters": "Filters",
    "filters__clear": "清除",
    "filters__clear_all_filters": "清除所有篩選條件",
    "filters__not_set": "(not set)",
    "filters__any_date": "(any date)",
    "filters__date_range": "{startDate} to {endDate}",
    "event_search__meta__description": "Search for events near {searchArea}",
    "event_search__meta__title": "賽事搜尋 | 威世智店家與賽事搜尋器",
    "page_not_found__header": "Page Not Found",
    "page_not_found__content": "We couldn't locate the page you're looking for.<br>The link you followed may be outdated, or we may have moved it since you last bookmarked it.<br>Or it may be temporarily unavailable.",
    "page_not_found__return": "Search for an event",
    "search_presets__stores_near_you": "Stores Near You",
    "search_presets__friday_night_magic": "Friday Night Magic",
    "search_presets__dungeons_and_dragons": "Dungeons & Dragons",
    "search_presets__magic_premier_events": "Magic Premier Events",
    "header__feedback": "意見反饋",
    "header__find_events": "查詢賽事",
    "header__title": "店家與賽事搜尋器",
    "header__classic_link": "Locator Classic",
    "header__search__near": "靠近：",
    "header__search__filter": "filter",
    "header__search__types__magic_events": "Magic Events",
    "header__search__types__magic_premier_events": "Magic Premier Events",
    "header__search__types__dnd": "D&D Events",
    "header__search__distance": "距離",
    "header__search__types__stores": "Stores",
    "header__search__distance__miles": "英里",
    "header__search__distance__kilometers": "km",
    "header__search__distance__unlimited": "unlimited",
    "header__search": "Search",
    "header__search__types__magic_premier_events_mobile": "Premier Events",
    "header__search__types__magic_events_mobile": "Magic",
    "header__search__types__dnd_mobile": "D&D",
    "header__search__types__stores_mobile": "Stores",
    "header__search__types__magic_events_desktop": "Magic Events",
    "header__search__types__magic_premier_events_desktop": "Premier Events",
    "header__search__types__dnd_desktop": "D&D Events",
    "header__search__types__stores_desktop": "Stores",
    "header__banner": "We have updated our <a target=\"_blank\" href=\"https://company.wizards.com/tou\">terms of use</a> and <a target=\"_blank\" href=\"https://company.wizards.com/policy\">privacy policy</a>. Click the links to learn more.",
    "store_details": "Store Details",
    "tag_text__two_headed_giant": "Magic with a partner",
    "tag_text__booster_draft": "Magic with cards selected from a booster",
    "tag_text__commander": "100-card Magic deck fun",
    "tag_text__legacy": "Build your deck out of cards from every Magic set",
    "tag_text__modern": "Play with many Magic sets",
    "tag_text__multiplayer": "Play Magic against many opponents",
    "tag_text__sealed_deck": "Play Magic with boosters provided to you",
    "tag_text__standard": "Play with recent Magic sets",
    "tag_text__pauper": "Play with only common Magic cards",
    "footer__brands__heading": "品牌",
    "footer__cities__heading": "Events in Other Cities",
    "footer__company__about": "關於",
    "footer__company__careers": "職位征聘",
    "footer__company__heading": "公司",
    "footer__company__support": "支援",
    "footer__company__wpn": "Wizards Play Network",
    "footer__copyrights": "© 1993-2024 Wizards of the Coast LLC, a subsidiary of Hasbro, Inc. 保留所有權利。",
    "footer__find__articles": "文章",
    "footer__find__club_support": "俱樂部支援",
    "footer__find__convention_support": "會展支援",
    "footer__find__digital_books": "電子圖書",
    "footer__find__formats": "賽制",
    "footer__find__forums": "論壇",
    "footer__find__heading": "究探",
    "footer__find__military_support": "軍事支援",
    "footer__find__rules": "規則",
    "footer__socials__connect": "Connect with Wizards",
    "footer__socials__connect2": "on social media",
    "footer__socials__heading": "社交媒體",
    "footer__utils__code_of_conduct": "行為準則",
    "footer__utils__cookies": "Cookies",
    "footer__utils__customer_support": "客戶服務",
    "footer__utils__fan_content_policy": "同好內容政策",
    "footer__utils__privacy_policy": "隱私政策",
    "footer__utils__terms": "條款",
    "footer__utils__donotsell": "請勿出售或揭露我的個人資訊。",
    "footer__privacy_banner__start_date": "01-07-2023",
    "footer__privacy_banner__days": "30",
    "footer__privacy_banner__message": "We have updated our <a href=\"https://company.wizards.com/en/legal/wizards-coasts-privacy-policy\">privacy policy</a>. Click the link to learn more.",
    "cookies__page__title": "What is a cookie?",
    "cookies__page__describe_cookies": "Cookies are small text files that are placed on your device by apps and websites that you visit. Cookies are widely used in order to make websites work, fulfil your preferences, and to provide information to the owners of websites. The table below explains the cookies we use and why.",
    "cookies__page__table_header__cookie_name": "Cookie Name",
    "cookies__page__table_header__domain": "Domain",
    "cookies__page__table_header__description": "Description",
    "cookies__description__gat": "Used to throttle request rate. if Google Analytics is deployed via Google Tag manager, this cookie will be named _dc_gtm_15020098",
    "cookies__description__gid": "Used to distinguish users.",
    "cookies__description__ga": "Used to distinguish users.",
    "cookies__description__cookie_agree": "This cookie is set as part of cookie notification on the Event Locator web site.",
    "cookies__description__aec": "These cookies prevent malicious sites from acting on behalf of a user without that user’s knowledge",
    "cookies__description__nid": "The “NID” cookie is used to play YouTube videos embedded on the website.",
    "cookies__description__enid": "This cookie is used by Google to remember your preferences and other information.",
    "cookies__description__doubleclick": "These cookies set by a third party (DoubleClick) and are used for serving targeted advertisements that are relevant to you across the web.",
    "cookies__banner__accept": "OK, I agree",
    "cookies__banner__decline": "No, thanks",
    "cookies__banner__copy": "We use necessary cookies to allow our site to function correctly and collect anonymous session data. Necessary cookies can be opted out through your browser settings. We also use optional cookies to personalize content and ads, provide social medial features and analyze web traffic. By clicking “OK, I agree,” you consent to optional cookies.  (<a href=\"{link}\">Learn more about cookies.</a>)",
    "event_content__description__booster_draft": "<p>想在一個公平的競爭環境中進行遊戲，同時查看新牌張嗎？補充包輪抽這類限制賽制可能是你的理想之選。有別於構組賽制（你前往參賽時需帶備一副精心構組並隨時可用的套牌），限制賽制讓你可利用新牌張構組套牌。</p><p>於補充包輪抽開始時，每位玩家拆開一包補充包並選一張牌。（切勿向其他玩家展示你所選的牌！）然後每人將會把其補充包內餘下的牌傳給左邊的玩家，每位玩家便會從剛接到的補充包內選一張牌，其後繼續傳下去。如此進行下去，直到所有的牌都選完為止。然後，每位玩家打開第二包補充包，但這次要向右傳；在所有的牌都選完後，將會對第三包補充包重複同一步驟，再次向左傳。</p>",
    "event_content__description__dnd": "<p>龍與地下城角色扮演遊戲講述了關於劍與巫術的世界之故事。它與孩童的假扮遊戲有著相同的元素。像那些遊戲一樣，龍與地下城是由想像力驅動的。它是關於描繪暴風雨夜空下的高聳城堡，並想像一個幻想冒險家如何應對場景帶來的挑戰。</p><p>有別於假扮遊戲，龍與地下城為故事提供了結構，這是一種確定冒險者行為後果的方法。玩家擲骰子以決定他們的攻擊是否命中或者他們的冒險者是否可以攀爬懸崖、遠離魔法閃電的擊打，或者完成其他危險任務。一切皆有可能，但骰子能使某些結果更有可能實現。</p><p>在龍與地下城遊戲中，每位玩家都會建立一個冒險家（也稱為人物），並與其他冒險者（由朋友擔任）組成團隊。團隊將會共同合作，可能會一起探索黑暗的地牢、破落的城市、鬧鬼的城堡、叢林深處的失落廟宇，或者神秘山峰下的熔岩洞穴。冒險者可以解決難題、與其他人物交談、與奇幻的怪物戰鬥，以及發現精美的魔法物品和其他寶藏。</p>",
    "event_content__description__magic": "無論你是之前從未接觸過集換式卡牌遊戲，或是你的衣櫃裡裝滿了卡牌遊戲，魔法風雲會都值得你一試。在線上或桌面遊戲中體驗激烈的法師對決。探索30年之久的故事、人物和插畫。在你當地遊戲店的魔法風雲會賽事上結交新朋。參與魔法風雲會社群的方式多種多樣，我們很高興你能加入！",
    "event_content__description__modern": "<p>Modern is a constructed format and therefore adheres to the following constructed rules:</p><p>Minimum of sixty cards No maximum deck size, as long as you can shuffle your deck unassisted Up to fifteen cards in your sideboard, if used With the exception of basic lands (Plains, Island, Swamp, Mountain, and Forest), your combined deck and sideboard may not contain more than four of any individual card, counted by its English card title equivalent.</p><p>Note: The five snow-covered lands from the Ice Age block—Snow-Covered Plains, Snow-Covered Island, Snow-Covered Swamp, Snow-Covered Mountain, and Snow-Covered Forest—are also basic lands. Snow-covered lands are only permitted in formats that allow expansions from the Ice Age block.</p>",
    "event_content__description__standard": "Standard is a dynamic format where you build decks and play using cards in your collection from recently released Magic sets. Evolving gameplay and fresh strategies make it one of the most fun and popular ways to play Magic.",
    "event_content__format": "賽制：補充包輪抽:",
    "event_content__format__modern": "Modern",
    "event_content__format__standard": "Standard",
    "event_content__format__booster_draft": "Booster Draft",
    "footer__utils__privacy_settings": "您的隱私權選擇"
  }
}
