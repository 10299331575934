export default {
  "pt": {
    "glossary__status__errored": "We're sorry, we're not able to retrieve this information at the moment, please try back later",
    "glossary__status__invalid__location": "Not a valid search location, try for a different location",
    "glossary__status__loading": "Loading...",
    "glossary__status__no_events": "No events found for the location, distance and filters specified",
    "pagination__next": "Próximo",
    "pagination__previous": "Anterior",
    "sort_selector__date": "Data",
    "sort_selector__distance": "Distance",
    "event_details": "Detalhes do evento",
    "event_details__add_to_calendar": "Adicionar ao calendário",
    "event_details__meta__description__fallback": "Details of your event",
    "event_details__meta__title": "{eventName}: {eventDate} at {organizationName}",
    "filters": "Filters",
    "filters__clear": "Limpar",
    "filters__clear_all_filters": "Limpar todos os filtros",
    "filters__not_set": "(not set)",
    "filters__any_date": "(any date)",
    "filters__date_range": "{startDate} to {endDate}",
    "event_search__meta__description": "Search for events near {searchArea}",
    "event_search__meta__title": "Busca de Eventos | Localizador de Lojas e Eventos da Wizards",
    "page_not_found__header": "Page Not Found",
    "page_not_found__content": "We couldn't locate the page you're looking for.<br>The link you followed may be outdated, or we may have moved it since you last bookmarked it.<br>Or it may be temporarily unavailable.",
    "page_not_found__return": "Search for an event",
    "search_presets__stores_near_you": "Stores Near You",
    "search_presets__friday_night_magic": "Friday Night Magic",
    "search_presets__dungeons_and_dragons": "Dungeons & Dragons",
    "search_presets__magic_premier_events": "Magic Premier Events",
    "header__feedback": "Comentários",
    "header__find_events": "Encontre Eventos",
    "header__title": "Localizador de Lojas e Eventos",
    "header__classic_link": "Locator Classic",
    "header__search__near": "Próximos:",
    "header__search__filter": "filter",
    "header__search__types__magic_events": "Magic Events",
    "header__search__types__magic_premier_events": "Magic Premier Events",
    "header__search__types__dnd": "D&D Events",
    "header__search__distance": "distância",
    "header__search__types__stores": "Stores",
    "header__search__distance__miles": "km",
    "header__search__distance__kilometers": "km",
    "header__search__distance__unlimited": "unlimited",
    "header__search": "Search",
    "header__search__types__magic_premier_events_mobile": "Premier Events",
    "header__search__types__magic_events_mobile": "Magic",
    "header__search__types__dnd_mobile": "D&D",
    "header__search__types__stores_mobile": "Stores",
    "header__search__types__magic_events_desktop": "Magic Events",
    "header__search__types__magic_premier_events_desktop": "Premier Events",
    "header__search__types__dnd_desktop": "D&D Events",
    "header__search__types__stores_desktop": "Stores",
    "header__banner": "Atualizamos nossos <a target=\"_blank\" href=\"https://company.wizards.com/tou\">termos de uso</a> e <a target=\"_blank\" href=\"https://company.wizards.com/policy\">política de privacidade.</a> Clique nos links para saber mais.",
    "store_details": "Store Details",
    "tag_text__two_headed_giant": "Magic with a partner",
    "tag_text__booster_draft": "Magic with cards selected from a booster",
    "tag_text__commander": "100-card Magic deck fun",
    "tag_text__legacy": "Build your deck out of cards from every Magic set",
    "tag_text__modern": "Play with many Magic sets",
    "tag_text__multiplayer": "Play Magic against many opponents",
    "tag_text__sealed_deck": "Play Magic with boosters provided to you",
    "tag_text__standard": "Play with recent Magic sets",
    "tag_text__pauper": "Play with only common Magic cards",
    "footer__brands__heading": "Marcas",
    "footer__cities__heading": "Events in Other Cities",
    "footer__company__about": "Sobre",
    "footer__company__careers": "Carreiras",
    "footer__company__heading": "Empresa",
    "footer__company__support": "Suporte",
    "footer__company__wpn": "Wizards Play Network",
    "footer__copyrights": "© 1993-2024 Wizards of the Coast LLC, uma subsidiária da Hasbro, Inc. Todos os direitos reservados.",
    "footer__find__articles": "Artigos",
    "footer__find__club_support": "Apoio ao Clube",
    "footer__find__convention_support": "Apoio a Convenções",
    "footer__find__digital_books": "Livros digitais",
    "footer__find__formats": "Formatos",
    "footer__find__forums": "Fóruns",
    "footer__find__heading": "Encontre",
    "footer__find__military_support": "Apoio Militar",
    "footer__find__rules": "Regras",
    "footer__socials__connect": "Connect with Wizards",
    "footer__socials__connect2": "on social media",
    "footer__socials__heading": "Social",
    "footer__utils__code_of_conduct": "Código de Conduta",
    "footer__utils__cookies": "Cookies",
    "footer__utils__customer_support": "Atendimento ao Cliente",
    "footer__utils__fan_content_policy": "Política de Conteúdo de Fãs",
    "footer__utils__privacy_policy": "Política de Privacidade",
    "footer__utils__terms": "Termos",
    "footer__utils__donotsell": "Não vender ou compartilhar minhas informações pessoais",
    "footer__privacy_banner__start_date": "01-07-2023",
    "footer__privacy_banner__days": "30",
    "footer__privacy_banner__message": "We have updated our <a href=\"https://company.wizards.com/en/legal/wizards-coasts-privacy-policy\">privacy policy</a>. Click the link to learn more.",
    "cookies__page__title": "What is a cookie?",
    "cookies__page__describe_cookies": "Cookies are small text files that are placed on your device by apps and websites that you visit. Cookies are widely used in order to make websites work, fulfil your preferences, and to provide information to the owners of websites. The table below explains the cookies we use and why.",
    "cookies__page__table_header__cookie_name": "Cookie Name",
    "cookies__page__table_header__domain": "Domain",
    "cookies__page__table_header__description": "Description",
    "cookies__description__gat": "Used to throttle request rate. if Google Analytics is deployed via Google Tag manager, this cookie will be named _dc_gtm_15020098",
    "cookies__description__gid": "Used to distinguish users.",
    "cookies__description__ga": "Used to distinguish users.",
    "cookies__description__cookie_agree": "This cookie is set as part of cookie notification on the Event Locator web site.",
    "cookies__description__aec": "These cookies prevent malicious sites from acting on behalf of a user without that user’s knowledge",
    "cookies__description__nid": "The “NID” cookie is used to play YouTube videos embedded on the website.",
    "cookies__description__enid": "This cookie is used by Google to remember your preferences and other information.",
    "cookies__description__doubleclick": "These cookies set by a third party (DoubleClick) and are used for serving targeted advertisements that are relevant to you across the web.",
    "cookies__banner__accept": "OK, I agree",
    "cookies__banner__decline": "No, thanks",
    "cookies__banner__copy": "We use necessary cookies to allow our site to function correctly and collect anonymous session data. Necessary cookies can be opted out through your browser settings. We also use optional cookies to personalize content and ads, provide social medial features and analyze web traffic. By clicking “OK, I agree,” you consent to optional cookies.  (<a href=\"{link}\">Learn more about cookies.</a>)",
    "event_content__description__booster_draft": "<p>Quer um modo de jogar que ofereça uma jogabilidade nivelada e ao mesmo tempo permita conhecer novos cards? Então, um formato limitado como booster draft é o que você precisa. Diferentemente dos formatos construídos nos quais você chega com decks preparados cuidadosamente e prontos para jogar, os formatos limitados permitem construir um deck com novos cards como parte do jogo.</p><p>No início do booster draft, cada jogador abre um booster e escolhe um card. (Não mostre aos outros jogadores qual você escolheu!) Depois, cada um passa o resto do pacote para o jogador à esquerda, cada jogador pega um card do pacote recebido e passa novamente. Esse processo continua até todos os cards do pacote terem sido escolhidos. Então, cada jogador abre um segundo booster, mas desta vez ele é passado para a direita. Depois que todos os cards forem escolhidos, você faz o mesmo com o terceiro pacote, passando para a esquerda novamente.</p>",
    "event_content__description__dnd": "<p>O jogo de RPG de Dungeons & Dragons é sobre histórias em mundos de espadas e feitiços. Ele tem elementos de jogos de imaginação da infância. Assim como esse jogos, D&D é guiado pela imaginação. Trata-se de pensar num castelo imponente, sob uma noite de céu tempestuoso, e imaginar como um personagem fantástico viveria os desafios que este cenário apresenta.</p><p>Ao contrário de um jogo de pura imaginação, o D&D estrutura as histórias, como uma forma de determinar as consequências das ações do personagem. Os jogadores jogam os dados para resolverem se acertam ou erram seus ataques, ou se seu personagem pode escalar uma montanha, escapar de ser atingido por um raio mágico, ou realizar outras tarefas perigosas. Tudo é possível, mas os dados tornam alguns desfechos mais prováveis que outros.</p><p>No jogo Dungeons & Dragons, cada jogador cria um aventureiro (também chamado de personagem) e se junta a outros aventureiros (vividos pelos amigos). Trabalhando junto, o grupo pode explorar uma masmorra escura, uma cidade em ruínas, um castelo assombrado, um tempo perdido em meio à selva, ou uma caverna cheia de lava abaixo de uma montanha misteriosa. Os personagens podem resolver quebra-cabeças, conversar com outros aventureiros, lutar contra monstros fantásticos e descobrir itens mágicos fabulosos e outros tesouros.</p>",
    "event_content__description__magic": "Se você nunca jogou um jogo de estampas ilustradas ou se seu guarda-roupas está cheio de cards, Magic: The Gathering tem algo para te oferecer. Jogue on-line ou em papel para experimentar a emoção de um duelo de magos. Explore 30 anos de histórias, personagens e artes. Faça novos amigos em eventos de Magic na sua loja de jogos local. Existem muitas formas de fazer parte da comunidade de Magic, e estamos felizes de ter você!",
    "event_content__description__modern": "<p>Modern is a constructed format and therefore adheres to the following constructed rules:</p><p>Minimum of sixty cards No maximum deck size, as long as you can shuffle your deck unassisted Up to fifteen cards in your sideboard, if used With the exception of basic lands (Plains, Island, Swamp, Mountain, and Forest), your combined deck and sideboard may not contain more than four of any individual card, counted by its English card title equivalent.</p><p>Note: The five snow-covered lands from the Ice Age block—Snow-Covered Plains, Snow-Covered Island, Snow-Covered Swamp, Snow-Covered Mountain, and Snow-Covered Forest—are also basic lands. Snow-covered lands are only permitted in formats that allow expansions from the Ice Age block.</p>",
    "event_content__description__standard": "Standard is a dynamic format where you build decks and play using cards in your collection from recently released Magic sets. Evolving gameplay and fresh strategies make it one of the most fun and popular ways to play Magic.",
    "event_content__format": "Formato:",
    "event_content__format__modern": "Modern",
    "event_content__format__standard": "Standard",
    "event_content__format__booster_draft": "Booster Draft",
    "footer__utils__privacy_settings": "Suas opções de privacidade"
  }
}
