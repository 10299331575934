export default {
  "ko": {
    "glossary__status__errored": "We're sorry, we're not able to retrieve this information at the moment, please try back later",
    "glossary__status__invalid__location": "Not a valid search location, try for a different location",
    "glossary__status__loading": "Loading...",
    "glossary__status__no_events": "No events found for the location, distance and filters specified",
    "pagination__next": "다음",
    "pagination__previous": "이전",
    "sort_selector__date": "날짜",
    "sort_selector__distance": "Distance",
    "event_details": "이벤트 세부 사항",
    "event_details__add_to_calendar": "일정에 추가",
    "event_details__meta__description__fallback": "Details of your event",
    "event_details__meta__title": "{eventName}: {eventDate} at {organizationName}",
    "filters": "Filters",
    "filters__clear": "지우기",
    "filters__clear_all_filters": "모든 필터 지우기",
    "filters__not_set": "(not set)",
    "filters__any_date": "(any date)",
    "filters__date_range": "{startDate} to {endDate}",
    "event_search__meta__description": "Search for events near {searchArea}",
    "event_search__meta__title": "이벤트 검색 | 위저즈 매장 및 이벤트 탐색기",
    "page_not_found__header": "Page Not Found",
    "page_not_found__content": "We couldn't locate the page you're looking for.<br>The link you followed may be outdated, or we may have moved it since you last bookmarked it.<br>Or it may be temporarily unavailable.",
    "page_not_found__return": "Search for an event",
    "search_presets__stores_near_you": "Stores Near You",
    "search_presets__friday_night_magic": "Friday Night Magic",
    "search_presets__dungeons_and_dragons": "Dungeons & Dragons",
    "search_presets__magic_premier_events": "Magic Premier Events",
    "header__feedback": "피드백",
    "header__find_events": "이벤트 찾기",
    "header__title": "매장 및 이벤트 탐색기",
    "header__classic_link": "Locator Classic",
    "header__search__near": "가까운 이벤트 찾기:",
    "header__search__filter": "filter",
    "header__search__types__magic_events": "Magic Events",
    "header__search__types__magic_premier_events": "Magic Premier Events",
    "header__search__types__dnd": "D&D Events",
    "header__search__distance": "거리",
    "header__search__types__stores": "Stores",
    "header__search__distance__miles": "mi",
    "header__search__distance__kilometers": "km",
    "header__search__distance__unlimited": "unlimited",
    "header__search": "Search",
    "header__search__types__magic_premier_events_mobile": "Premier Events",
    "header__search__types__magic_events_mobile": "Magic",
    "header__search__types__dnd_mobile": "D&D",
    "header__search__types__stores_mobile": "Stores",
    "header__search__types__magic_events_desktop": "Magic Events",
    "header__search__types__magic_premier_events_desktop": "Premier Events",
    "header__search__types__dnd_desktop": "D&D Events",
    "header__search__types__stores_desktop": "Stores",
    "header__banner": "We have updated our <a target=\"_blank\" href=\"https://company.wizards.com/tou\">terms of use</a> and <a target=\"_blank\" href=\"https://company.wizards.com/policy\">privacy policy</a>. Click the links to learn more.",
    "store_details": "Store Details",
    "tag_text__two_headed_giant": "Magic with a partner",
    "tag_text__booster_draft": "Magic with cards selected from a booster",
    "tag_text__commander": "100-card Magic deck fun",
    "tag_text__legacy": "Build your deck out of cards from every Magic set",
    "tag_text__modern": "Play with many Magic sets",
    "tag_text__multiplayer": "Play Magic against many opponents",
    "tag_text__sealed_deck": "Play Magic with boosters provided to you",
    "tag_text__standard": "Play with recent Magic sets",
    "tag_text__pauper": "Play with only common Magic cards",
    "footer__brands__heading": "브랜드",
    "footer__cities__heading": "Events in Other Cities",
    "footer__company__about": "정보",
    "footer__company__careers": "채용",
    "footer__company__heading": "회사",
    "footer__company__support": "지원",
    "footer__company__wpn": "위저즈 플레이 네트워크",
    "footer__copyrights": "© 1993-2024 Wizards of the Coast LLC, a subsidiary of Hasbro, Inc. All Rights Reserved.",
    "footer__find__articles": "기사",
    "footer__find__club_support": "클럽 지원",
    "footer__find__convention_support": "컨벤션 지원",
    "footer__find__digital_books": "디지털 서적",
    "footer__find__formats": "형식",
    "footer__find__forums": "게시판",
    "footer__find__heading": "검색",
    "footer__find__military_support": "군부대 지원",
    "footer__find__rules": "규칙",
    "footer__socials__connect": "Connect with Wizards",
    "footer__socials__connect2": "on social media",
    "footer__socials__heading": "소셜",
    "footer__utils__code_of_conduct": "윤리강령",
    "footer__utils__cookies": "쿠키",
    "footer__utils__customer_support": "고객 지원",
    "footer__utils__fan_content_policy": "팬 콘텐츠 정책",
    "footer__utils__privacy_policy": "개인정보 보호정책",
    "footer__utils__terms": "약관",
    "footer__utils__donotsell": "내 개인정보를 판매하거나 공유하지 마십시오",
    "footer__privacy_banner__start_date": "01-07-2023",
    "footer__privacy_banner__days": "30",
    "footer__privacy_banner__message": "We have updated our <a href=\"https://company.wizards.com/en/legal/wizards-coasts-privacy-policy\">privacy policy</a>. Click the link to learn more.",
    "cookies__page__title": "What is a cookie?",
    "cookies__page__describe_cookies": "Cookies are small text files that are placed on your device by apps and websites that you visit. Cookies are widely used in order to make websites work, fulfil your preferences, and to provide information to the owners of websites. The table below explains the cookies we use and why.",
    "cookies__page__table_header__cookie_name": "Cookie Name",
    "cookies__page__table_header__domain": "Domain",
    "cookies__page__table_header__description": "Description",
    "cookies__description__gat": "Used to throttle request rate. if Google Analytics is deployed via Google Tag manager, this cookie will be named _dc_gtm_15020098",
    "cookies__description__gid": "Used to distinguish users.",
    "cookies__description__ga": "Used to distinguish users.",
    "cookies__description__cookie_agree": "This cookie is set as part of cookie notification on the Event Locator web site.",
    "cookies__description__aec": "These cookies prevent malicious sites from acting on behalf of a user without that user’s knowledge",
    "cookies__description__nid": "The “NID” cookie is used to play YouTube videos embedded on the website.",
    "cookies__description__enid": "This cookie is used by Google to remember your preferences and other information.",
    "cookies__description__doubleclick": "These cookies set by a third party (DoubleClick) and are used for serving targeted advertisements that are relevant to you across the web.",
    "cookies__banner__accept": "OK, I agree",
    "cookies__banner__decline": "No, thanks",
    "cookies__banner__copy": "We use necessary cookies to allow our site to function correctly and collect anonymous session data. Necessary cookies can be opted out through your browser settings. We also use optional cookies to personalize content and ads, provide social medial features and analyze web traffic. By clicking “OK, I agree,” you consent to optional cookies.  (<a href=\"{link}\">Learn more about cookies.</a>)",
    "event_content__description__booster_draft": "<p>수준에 맞게 플레이하면서 동시에 신규 카드도 확인할 수 있는 방법을 찾고 계십니까? 리미티드 형식인 부스터 드래프트를 추천합니다. 바로 게임을 할 수 있도록 미리 구성한 덱을 가져오는 컨스트럭티드 형식과 달리, 리미티드 형식은 새로운 카드로 덱을 구성하는 작업이 게임의 일부인 형식입니다.</p><p>부스터 드래프트를 시작하면 각 플레이어는 부스터팩을 개봉하고 카드 한 장을 선택합니다. (다른 플레이어에게 자신이 선택한 카드를 보여 주지 마세요!) 그런 다음 나머지 팩을 자신의 왼쪽에 있는 플레이어에게 주고 각 플레이어는 자신이 받은 팩에서 다시 한 번 카드를 뽑습니다. 그렇게 팩에 든 모든 카드들이 선택될 때까지 이 과정을 반복합니다. 그리고 각 플레이어는 두 번째 부스터팩을 개봉하고 한 장을 고르지만, 이번에는 자신의 오른쪽으로 나머지 카드들을 넘깁니다. 모든 카드가 선택되면 세 번째 팩을 같은 방식으로 왼쪽으로 넘깁니다.</p>",
    "event_content__description__dnd": "<p>Dungeons & Dragons 롤플레잉 게임은 검과 마법의 세계에 대한 이야기를 다룹니다. 아이들의 가상의 역할 게임과 유사한 요소를 공유하는 게임입니다. 그런 게임들처럼, D&D는 상상력을 기반으로 플레이합니다. 폭풍우가 몰아치는 어두운 밤하늘 아래 우뚝 솟은 성채를 묘사하고, 그러한 장면에서 주어진 도전에 판타지 모험가가 어떻게 대처할 것인지 상상하는 것입니다.</p><p>가상의 역할 게임과 달리 D&D는 모험가의 동작을 결정하는 방식으로 이야기에 구조를 부여합니다. 플레이어는 주사위를 던져 공격의 성공 여부를 결정하거나, 자신의 모험가가 절벽을 기어오를지 또는 마법 번개를 굴러 피할지 등을 결정하고, 그 외 여러 위험한 과제를 완수하기도 합니다. 무엇이든 가능합니다. 하지만 주사위는 다른 결과보다 더 그럴듯한 결과를 만들어 냅니다.</p><p>Dungeons & Dragons 게임에서 각 플레이어는 모험가(또는 캐릭터)를 창조하고, 친구가 플레이하는 다른 모험가들과 팀을 구성합니다. 팀은 함께 협동하여 어둠의 던전, 폐허가 된 도시, 유령의 성, 깊은 정글 한 가운데 미지의 사원 또는 신비의 산 아래 용암이 가득한 동굴을 탐험하게 됩니다. 모험가들은 퍼즐을 풀고, 다른 캐릭터와 대화하고, 환상의 괴물과 싸울 수 있으며, 훌륭한 마법 아이템과 여러 보물을 발견하기도 합니다.</p>",
    "event_content__description__magic": "트레이딩 카드 게임을 경험한 적이 없는 초보자, 또는 여러 다양한 카드 게임을 경험해 본 숙련된 플레이어 모두에게 매직: 더 개더링은 특별한 매력을 선사합니다. 온라인과 오프라인을 통해 짜릿한 마법사들의 결투를 경험하십시오. 30년 분량의 스토리와 캐릭터, 삽화를 즐기십시오. 가까운 게임 매장의 매직 이벤트에서 새 친구들을 사귀십시오. 매직 커뮤니티의 일원이 되는 방법은 매우 다양하며, 여러분의 참여는 언제나 환영입니다.",
    "event_content__description__modern": "<p>Modern is a constructed format and therefore adheres to the following constructed rules:</p><p>Minimum of sixty cards No maximum deck size, as long as you can shuffle your deck unassisted Up to fifteen cards in your sideboard, if used With the exception of basic lands (Plains, Island, Swamp, Mountain, and Forest), your combined deck and sideboard may not contain more than four of any individual card, counted by its English card title equivalent.</p><p>Note: The five snow-covered lands from the Ice Age block—Snow-Covered Plains, Snow-Covered Island, Snow-Covered Swamp, Snow-Covered Mountain, and Snow-Covered Forest—are also basic lands. Snow-covered lands are only permitted in formats that allow expansions from the Ice Age block.</p>",
    "event_content__description__standard": "Standard is a dynamic format where you build decks and play using cards in your collection from recently released Magic sets. Evolving gameplay and fresh strategies make it one of the most fun and popular ways to play Magic.",
    "event_content__format": "형식:",
    "event_content__format__modern": "Modern",
    "event_content__format__standard": "Standard",
    "event_content__format__booster_draft": "Booster Draft",
    "footer__utils__privacy_settings": "개인정보 보호 선택 사항"
  }
}
