export default {
  "ja": {
    "glossary__status__errored": "We're sorry, we're not able to retrieve this information at the moment, please try back later",
    "glossary__status__invalid__location": "Not a valid search location, try for a different location",
    "glossary__status__loading": "Loading...",
    "glossary__status__no_events": "No events found for the location, distance and filters specified",
    "pagination__next": "次へ",
    "pagination__previous": "前へ",
    "sort_selector__date": "日程",
    "sort_selector__distance": "Distance",
    "event_details": "イベントの詳細",
    "event_details__add_to_calendar": "カレンダーに追加",
    "event_details__meta__description__fallback": "Details of your event",
    "event_details__meta__title": "{eventName}: {eventDate} at {organizationName}",
    "filters": "Filters",
    "filters__clear": "消去",
    "filters__clear_all_filters": "すべてのフィルターを解除",
    "filters__not_set": "(not set)",
    "filters__any_date": "(any date)",
    "filters__date_range": "{startDate} to {endDate}",
    "event_search__meta__description": "Search for events near {searchArea}",
    "event_search__meta__title": "イベント検索 | 「店舗・イベント検索」",
    "page_not_found__header": "Page Not Found",
    "page_not_found__content": "We couldn't locate the page you're looking for.<br>The link you followed may be outdated, or we may have moved it since you last bookmarked it.<br>Or it may be temporarily unavailable.",
    "page_not_found__return": "Search for an event",
    "search_presets__stores_near_you": "Stores Near You",
    "search_presets__friday_night_magic": "Friday Night Magic",
    "search_presets__dungeons_and_dragons": "Dungeons & Dragons",
    "search_presets__magic_premier_events": "Magic Premier Events",
    "header__feedback": "フィードバックを送る",
    "header__find_events": "イベントを探す",
    "header__title": "店舗・イベント検索",
    "header__classic_link": "Locator Classic",
    "header__search__near": "近隣の",
    "header__search__filter": "filter",
    "header__search__types__magic_events": "Magic Events",
    "header__search__types__magic_premier_events": "Magic Premier Events",
    "header__search__types__dnd": "D&D Events",
    "header__search__distance": "距離",
    "header__search__types__stores": "Stores",
    "header__search__distance__miles": "マイル以内",
    "header__search__distance__kilometers": "km",
    "header__search__distance__unlimited": "unlimited",
    "header__search": "Search",
    "header__search__types__magic_premier_events_mobile": "Premier Events",
    "header__search__types__magic_events_mobile": "Magic",
    "header__search__types__dnd_mobile": "D&D",
    "header__search__types__stores_mobile": "Stores",
    "header__search__types__magic_events_desktop": "Magic Events",
    "header__search__types__magic_premier_events_desktop": "Premier Events",
    "header__search__types__dnd_desktop": "D&D Events",
    "header__search__types__stores_desktop": "Stores",
    "header__banner": "We have updated our <a target=\"_blank\" href=\"https://company.wizards.com/tou\">terms of use</a> and <a target=\"_blank\" href=\"https://company.wizards.com/policy\">privacy policy</a>. Click the links to learn more.",
    "store_details": "Store Details",
    "tag_text__two_headed_giant": "Magic with a partner",
    "tag_text__booster_draft": "Magic with cards selected from a booster",
    "tag_text__commander": "100-card Magic deck fun",
    "tag_text__legacy": "Build your deck out of cards from every Magic set",
    "tag_text__modern": "Play with many Magic sets",
    "tag_text__multiplayer": "Play Magic against many opponents",
    "tag_text__sealed_deck": "Play Magic with boosters provided to you",
    "tag_text__standard": "Play with recent Magic sets",
    "tag_text__pauper": "Play with only common Magic cards",
    "footer__brands__heading": "ブランド",
    "footer__cities__heading": "Events in Other Cities",
    "footer__company__about": "私たちについて",
    "footer__company__careers": "採用情報",
    "footer__company__heading": "会社概要",
    "footer__company__support": "サポート",
    "footer__company__wpn": "ウィザーズ・プレイ・ネットワーク",
    "footer__copyrights": "© 1993-2024 Wizards of the Coast LLC, a subsidiary of Hasbro, Inc. All Rights Reserved.",
    "footer__find__articles": "記事",
    "footer__find__club_support": "クラブサポート",
    "footer__find__convention_support": "会場サポート",
    "footer__find__digital_books": "デジタル・ブック",
    "footer__find__formats": "フォーマット",
    "footer__find__forums": "フォーラム",
    "footer__find__heading": "注目",
    "footer__find__military_support": "Military Support",
    "footer__find__rules": "ルール",
    "footer__socials__connect": "Connect with Wizards",
    "footer__socials__connect2": "on social media",
    "footer__socials__heading": "ソーシャルメディア",
    "footer__utils__code_of_conduct": "行動規範",
    "footer__utils__cookies": "Cookie",
    "footer__utils__customer_support": "カスタマーサポート",
    "footer__utils__fan_content_policy": "ファンコンテンツポリシー",
    "footer__utils__privacy_policy": "プライバシーポリシー",
    "footer__utils__terms": "利用規約",
    "footer__utils__donotsell": "個人情報の販売や共有を許可しない",
    "footer__privacy_banner__start_date": "01-07-2023",
    "footer__privacy_banner__days": "30",
    "footer__privacy_banner__message": "We have updated our <a href=\"https://company.wizards.com/en/legal/wizards-coasts-privacy-policy\">privacy policy</a>. Click the link to learn more.",
    "cookies__page__title": "What is a cookie?",
    "cookies__page__describe_cookies": "Cookies are small text files that are placed on your device by apps and websites that you visit. Cookies are widely used in order to make websites work, fulfil your preferences, and to provide information to the owners of websites. The table below explains the cookies we use and why.",
    "cookies__page__table_header__cookie_name": "Cookie Name",
    "cookies__page__table_header__domain": "Domain",
    "cookies__page__table_header__description": "Description",
    "cookies__description__gat": "Used to throttle request rate. if Google Analytics is deployed via Google Tag manager, this cookie will be named _dc_gtm_15020098",
    "cookies__description__gid": "Used to distinguish users.",
    "cookies__description__ga": "Used to distinguish users.",
    "cookies__description__cookie_agree": "This cookie is set as part of cookie notification on the Event Locator web site.",
    "cookies__description__aec": "These cookies prevent malicious sites from acting on behalf of a user without that user’s knowledge",
    "cookies__description__nid": "The “NID” cookie is used to play YouTube videos embedded on the website.",
    "cookies__description__enid": "This cookie is used by Google to remember your preferences and other information.",
    "cookies__description__doubleclick": "These cookies set by a third party (DoubleClick) and are used for serving targeted advertisements that are relevant to you across the web.",
    "cookies__banner__accept": "OK, I agree",
    "cookies__banner__decline": "No, thanks",
    "cookies__banner__copy": "We use necessary cookies to allow our site to function correctly and collect anonymous session data. Necessary cookies can be opted out through your browser settings. We also use optional cookies to personalize content and ads, provide social medial features and analyze web traffic. By clicking “OK, I agree,” you consent to optional cookies.  (<a href=\"{link}\">Learn more about cookies.</a>)",
    "event_content__description__booster_draft": "<p>プレイできる場と、最新カードをチェックできる機会を同時にお望みですか？それなら、ブースタードラフトのようなリミテッドのフォーマットがお勧めです。入念に組まれたデッキを持参する構築フォーマットと違い、リミテッド・フォーマットではその場で渡されたカードでデッキを組むところからゲームが始まります。</p><p>ブースタードラフトの開始時に、各プレイヤーはブースターパックを１つ開けてカードを１枚だけ選びます。（選んだカードを他のプレイヤーに見せないように！）続いて全員がパックの残りを左隣のプレイヤーに渡し、各プレイヤーは受け取ったパックから１枚カードを選び、また渡します。これをパックのすべてのカードが選ばれるまで繰り返します。次に、各プレイヤーは２つ目のパックを開け、同様にカードを選びますが、今回は残りのカードは右に渡します。すべてのカードが選ばれたら、３つ目のパックで同様に、今度はまた左に渡します。</p>",
    "event_content__description__dnd": "<p>『ダンジョンズ＆ドラゴンズ』は、剣と魔法の世界の物語を体験するロールプレイングゲームです。幼少期の「ごっこ遊び」に似た要素を持つD＆Dは、プレイヤーの想像力によってゲームが進行します。嵐の夜にそびえる城を思い浮かべ、そこで待つ数々の危険に、勇敢な冒険者がどうやって立ち向うのかを想像するゲームなのです。</p><p>ごっこ遊びと違う点として、D&Dには物語を進める仕掛けがあります。冒険者の行動の結果を決める方法が定まっているのです。プレイヤーは、自分の操る冒険者の攻撃が当ったか外れたか、冒険者が崖を登りきれたか、魔法の電撃を避けることができたかなど、その他さまざまな危険な行為を首尾よくやってのけることができたかどうかを判定するために、ダイスを振ります。物語の中ではどんなことでも起こり得ます。ダイスの出目次第で、結果は変わるのです。</p><p>『ダンジョンズ＆ドラゴンズ』では、プレイヤーはそれぞれ１人の冒険者（キャラクターとも呼ばれます）を作成し、他の友達がプレイする冒険者とチームを組みます。チームは力を合わせて、暗い地下迷宮や廃墟と化した都市、呪われた古城、密林の謎の寺院、不思議な山の奥底の溶岩洞窟などを探険します。冒険者たちは謎を解き、他の登場人物と会話し、驚くべき怪物と戦い、伝説に残る魔法のアイテムやその他の宝物を見つけ出すのです。</p>",
    "event_content__description__magic": "トレーディング・カード・ゲームがはじめてだという人も、クローゼットがカードでいっぱいだという人も、マジック：ザ・ギャザリングはどんな人でも楽しめます。オンラインでも紙のカードでも、魔法使い同士のスリリングな戦いを体験できるのです。また、30年にわたって紡がれてきた物語やキャラクター、美しいアートなども魅力です。お近くのゲーム店へ足を運びマジックのイベントへ参加すれば、新しい友人も作れるでしょう。マジック・コミュニティの一員になるための方法はたくさんあります。皆さんを心から歓迎します！",
    "event_content__description__modern": "<p>Modern is a constructed format and therefore adheres to the following constructed rules:</p><p>Minimum of sixty cards No maximum deck size, as long as you can shuffle your deck unassisted Up to fifteen cards in your sideboard, if used With the exception of basic lands (Plains, Island, Swamp, Mountain, and Forest), your combined deck and sideboard may not contain more than four of any individual card, counted by its English card title equivalent.</p><p>Note: The five snow-covered lands from the Ice Age block—Snow-Covered Plains, Snow-Covered Island, Snow-Covered Swamp, Snow-Covered Mountain, and Snow-Covered Forest—are also basic lands. Snow-covered lands are only permitted in formats that allow expansions from the Ice Age block.</p>",
    "event_content__description__standard": "Standard is a dynamic format where you build decks and play using cards in your collection from recently released Magic sets. Evolving gameplay and fresh strategies make it one of the most fun and popular ways to play Magic.",
    "event_content__format": "フォーマット：ブースタードラフト:",
    "event_content__format__modern": "Modern",
    "event_content__format__standard": "Standard",
    "event_content__format__booster_draft": "Booster Draft",
    "footer__utils__privacy_settings": "プライバシーポリシー"
  }
}
