export default {
  "de": {
    "glossary__status__errored": "We're sorry, we're not able to retrieve this information at the moment, please try back later",
    "glossary__status__invalid__location": "Not a valid search location, try for a different location",
    "glossary__status__loading": "Loading...",
    "glossary__status__no_events": "No events found for the location, distance and filters specified",
    "pagination__next": "Weiter",
    "pagination__previous": "Zurück",
    "sort_selector__date": "Datum",
    "sort_selector__distance": "Distance",
    "event_details": "Event-Infos",
    "event_details__add_to_calendar": "Zu Kalender hinzufügen",
    "event_details__meta__description__fallback": "Details of your event",
    "event_details__meta__title": "{eventName}: {eventDate} at {organizationName}",
    "filters": "Filters",
    "filters__clear": "Löschen",
    "filters__clear_all_filters": "Alle Filter entfernen",
    "filters__not_set": "(not set)",
    "filters__any_date": "(any date)",
    "filters__date_range": "{startDate} to {endDate}",
    "event_search__meta__description": "Search for events near {searchArea}",
    "event_search__meta__title": "Event-Suche | Wizards Store- & Event-Locator",
    "page_not_found__header": "Page Not Found",
    "page_not_found__content": "We couldn't locate the page you're looking for.<br>The link you followed may be outdated, or we may have moved it since you last bookmarked it.<br>Or it may be temporarily unavailable.",
    "page_not_found__return": "Search for an event",
    "search_presets__stores_near_you": "Stores Near You",
    "search_presets__friday_night_magic": "Friday Night Magic",
    "search_presets__dungeons_and_dragons": "Dungeons & Dragons",
    "search_presets__magic_premier_events": "Magic Premier Events",
    "header__feedback": "Feedback",
    "header__find_events": "Events suchen",
    "header__title": "Store- & Event-Locator",
    "header__classic_link": "Locator Classic",
    "header__search__near": "in der Nähe von:",
    "header__search__filter": "filter",
    "header__search__types__magic_events": "Magic Events",
    "header__search__types__magic_premier_events": "Magic Premier Events",
    "header__search__types__dnd": "D&D Events",
    "header__search__distance": "Entfernung",
    "header__search__types__stores": "Stores",
    "header__search__distance__miles": "Meilen",
    "header__search__distance__kilometers": "km",
    "header__search__distance__unlimited": "unlimited",
    "header__search": "Search",
    "header__search__types__magic_premier_events_mobile": "Premier Events",
    "header__search__types__magic_events_mobile": "Magic",
    "header__search__types__dnd_mobile": "D&D",
    "header__search__types__stores_mobile": "Stores",
    "header__search__types__magic_events_desktop": "Magic Events",
    "header__search__types__magic_premier_events_desktop": "Premier Events",
    "header__search__types__dnd_desktop": "D&D Events",
    "header__search__types__stores_desktop": "Stores",
    "header__banner": "We have updated our <a target=\"_blank\" href=\"https://company.wizards.com/tou\">terms of use</a> and <a target=\"_blank\" href=\"https://company.wizards.com/policy\">privacy policy</a>. Click the links to learn more.",
    "store_details": "Store Details",
    "tag_text__two_headed_giant": "Magic with a partner",
    "tag_text__booster_draft": "Magic with cards selected from a booster",
    "tag_text__commander": "100-card Magic deck fun",
    "tag_text__legacy": "Build your deck out of cards from every Magic set",
    "tag_text__modern": "Play with many Magic sets",
    "tag_text__multiplayer": "Play Magic against many opponents",
    "tag_text__sealed_deck": "Play Magic with boosters provided to you",
    "tag_text__standard": "Play with recent Magic sets",
    "tag_text__pauper": "Play with only common Magic cards",
    "footer__brands__heading": "Marken",
    "footer__cities__heading": "Events in Other Cities",
    "footer__company__about": "Unternehmensinfos",
    "footer__company__careers": "Karriere",
    "footer__company__heading": "Unternehmens-Homepage",
    "footer__company__support": "Support",
    "footer__company__wpn": "Wizards Play Network",
    "footer__copyrights": "© 1993-2024 Wizards of the Coast LLC, eine Tochtergesellschaft von Hasbro, Inc. Alle Rechte vorbehalten.",
    "footer__find__articles": "Artikel",
    "footer__find__club_support": "Club Support",
    "footer__find__convention_support": "Convention Support",
    "footer__find__digital_books": "E-Books",
    "footer__find__formats": "Formate",
    "footer__find__forums": "Foren",
    "footer__find__heading": "Nützliche Links",
    "footer__find__military_support": "Military Support",
    "footer__find__rules": "Regeln",
    "footer__socials__connect": "Connect with Wizards",
    "footer__socials__connect2": "on social media",
    "footer__socials__heading": "Soziale Medien",
    "footer__utils__code_of_conduct": "Verhaltensregeln",
    "footer__utils__cookies": "Cookies",
    "footer__utils__customer_support": "Kundendienst",
    "footer__utils__fan_content_policy": "Richtlinie für Fan-Inhalte",
    "footer__utils__privacy_policy": "Datenschutzerklärung",
    "footer__utils__terms": "Nutzungsbedingungen",
    "footer__utils__donotsell": "Meine persönlichen Daten dürfen nicht verkauft oder geteilt werden.",
    "footer__privacy_banner__start_date": "01-07-2023",
    "footer__privacy_banner__days": "30",
    "footer__privacy_banner__message": "We have updated our <a href=\"https://company.wizards.com/en/legal/wizards-coasts-privacy-policy\">privacy policy</a>. Click the link to learn more.",
    "cookies__page__title": "What is a cookie?",
    "cookies__page__describe_cookies": "Cookies are small text files that are placed on your device by apps and websites that you visit. Cookies are widely used in order to make websites work, fulfil your preferences, and to provide information to the owners of websites. The table below explains the cookies we use and why.",
    "cookies__page__table_header__cookie_name": "Cookie Name",
    "cookies__page__table_header__domain": "Domain",
    "cookies__page__table_header__description": "Description",
    "cookies__description__gat": "Used to throttle request rate. if Google Analytics is deployed via Google Tag manager, this cookie will be named _dc_gtm_15020098",
    "cookies__description__gid": "Used to distinguish users.",
    "cookies__description__ga": "Used to distinguish users.",
    "cookies__description__cookie_agree": "This cookie is set as part of cookie notification on the Event Locator web site.",
    "cookies__description__aec": "These cookies prevent malicious sites from acting on behalf of a user without that user’s knowledge",
    "cookies__description__nid": "The “NID” cookie is used to play YouTube videos embedded on the website.",
    "cookies__description__enid": "This cookie is used by Google to remember your preferences and other information.",
    "cookies__description__doubleclick": "These cookies set by a third party (DoubleClick) and are used for serving targeted advertisements that are relevant to you across the web.",
    "cookies__banner__accept": "OK, I agree",
    "cookies__banner__decline": "No, thanks",
    "cookies__banner__copy": "We use necessary cookies to allow our site to function correctly and collect anonymous session data. Necessary cookies can be opted out through your browser settings. We also use optional cookies to personalize content and ads, provide social medial features and analyze web traffic. By clicking “OK, I agree,” you consent to optional cookies.  (<a href=\"{link}\">Learn more about cookies.</a>)",
    "event_content__description__booster_draft": "<p>Suchst du eine Art zu spielen, die fair für alle ist und bei der du gleichzeitig neue Karten ausprobieren kannst? Dann ist ein Limited-Format wie Boosterdraft genau das Richtige für dich. Im Gegensatz zu Constructed-Formaten, bei denen die Spieler mit sorgfältig gebauten Decks spielen, ist beim Limited-Format das Zusammenstellen eines neuen Decks Teil des Spiels.</p><p>Zu Beginn eines Boosterdrafts öffnet jeder Spieler eine Boosterpackung und sucht sich eine Karte aus. (Zeig den anderen Spielern auf keinen Fall, was du dir ausgesucht hast!) Dann geben alle die restlichen Karten der Packung an den Spieler zu ihrer Linken weiter. Jeder Spieler sucht sich dann wieder eine Karte aus dem Pack aus, bevor er es wieder weitergibt. Das macht man solange, bis alle Karten aus den Boosterpackungen einen Besitzer gefunden haben. Als Nächstes öffnet jeder Spieler seine zweite Boosterpackung, aber diesmal werden die verbleibenden Karten nach rechts weitergegeben. Nachdem alle Karten verteilt wurden, wird die dritte Boosterpackung geöffnet und diesmal wieder nach links gereicht.</p>",
    "event_content__description__dnd": "<p>Im Rollenspiel Dungeons & Dragons geht es darum, Geschichten aus Welten voller Schwerter und Zauberei zu erzählen. Einige Elemente erinnern an Kinder, die in Fantasiewelten spielen. Wie bei solchen Spielen ist auch bei D&D die Fantasie die treibende Kraft. Es geht darum, die hoch aufragende Burg in einer stürmischen Nacht vor seinem geistigen Auge zu sehen und sich vorzustellen, wie ein Fantasie-Abenteurer auf die Herausforderungen reagieren könnte, vor die ihn diese Szene stellt.</p><p>Im Gegensatz zum Spielen bei Kindern gibt D&D den Geschichten Struktur und ermöglicht es, die Konsequenzen des Handelns der Abenteurer zu bestimmen. Spieler würfeln, um zu ermitteln, ob ihre Angriffe treffen oder nicht oder ob ihre Abenteurer eine Klippe hinaufklettern, einem magischen Blitzschlag ausweichen oder eine andere gefährliche Aufgabe erfüllen können. Alles ist möglich, aber die Würfel machen manche Ergebnisse wahrscheinlicher als andere.</p><p>Im Spiel Dungeons & Dragons erstellt jeder Spieler einen Abenteurer (auch als „Charakter“ bezeichnet) und schließt sich mit anderen Abenteurern (die von Freunden gespielt werden) zusammen. Zusammen erkundet die Gruppe möglicherweise ein dunkles Verlies, eine Ruinenstadt, ein Spukschloss, einen vergessenen Tempel tief im Dschungel oder ein mit Lava gefülltes Gewölbe unter einem geheimnisvollen Berg. Die Abenteurer können Rätsel lösen, mit anderen Charakteren sprechen, gegen fantastische Monster kämpfen sowie fabelhafte magische Gegenstände und andere Schätze entdecken.</p>",
    "event_content__description__magic": "Unabhängig davon, ob du noch nie ein Sammelkartenspiel gespielt hast oder schon einen ganzen Schrank voller Kartenspiele hast, hat Magic: The Gathering dir garantiert etwas zu bieten. Erlebe den Nervenkitzel eines Duells der Zauberer, egal ob online oder mit echten Karten. Erkunde 30 Jahre voller Geschichten, Charaktere und Illustrationen. Finde bei Magic-Events in deinem lokalen Store neue Freunde. Es gibt viele verschiedene Möglichkeiten, Teil der Magic-Community zu sein, und jedes Mitglied ist eine Bereicherung!",
    "event_content__description__modern": "<p>Modern is a constructed format and therefore adheres to the following constructed rules:</p><p>Minimum of sixty cards No maximum deck size, as long as you can shuffle your deck unassisted Up to fifteen cards in your sideboard, if used With the exception of basic lands (Plains, Island, Swamp, Mountain, and Forest), your combined deck and sideboard may not contain more than four of any individual card, counted by its English card title equivalent.</p><p>Note: The five snow-covered lands from the Ice Age block—Snow-Covered Plains, Snow-Covered Island, Snow-Covered Swamp, Snow-Covered Mountain, and Snow-Covered Forest—are also basic lands. Snow-covered lands are only permitted in formats that allow expansions from the Ice Age block.</p>",
    "event_content__description__standard": "Standard is a dynamic format where you build decks and play using cards in your collection from recently released Magic sets. Evolving gameplay and fresh strategies make it one of the most fun and popular ways to play Magic.",
    "event_content__format": "Format:",
    "event_content__format__modern": "Modern",
    "event_content__format__standard": "Standard",
    "event_content__format__booster_draft": "Booster Draft",
    "footer__utils__privacy_settings": "Ihre Datenschutzwahlen"
  }
}
