export default {
  "zh-CN": {
    "glossary__status__errored": "We're sorry, we're not able to retrieve this information at the moment, please try back later",
    "glossary__status__invalid__location": "Not a valid search location, try for a different location",
    "glossary__status__loading": "Loading...",
    "glossary__status__no_events": "No events found for the location, distance and filters specified",
    "pagination__next": "后一页",
    "pagination__previous": "前一页",
    "sort_selector__date": "日期",
    "sort_selector__distance": "Distance",
    "event_details": "赛事详情",
    "event_details__add_to_calendar": "添加至日历",
    "event_details__meta__description__fallback": "Details of your event",
    "event_details__meta__title": "{eventName}: {eventDate} at {organizationName}",
    "filters": "Filters",
    "filters__clear": "清除",
    "filters__clear_all_filters": "清除所有筛选条件",
    "filters__not_set": "(not set)",
    "filters__any_date": "(any date)",
    "filters__date_range": "{startDate} to {endDate}",
    "event_search__meta__description": "Search for events near {searchArea}",
    "event_search__meta__title": "赛事搜寻 | 威世智店家与赛事搜寻器",
    "page_not_found__header": "Page Not Found",
    "page_not_found__content": "We couldn't locate the page you're looking for.<br>The link you followed may be outdated, or we may have moved it since you last bookmarked it.<br>Or it may be temporarily unavailable.",
    "page_not_found__return": "Search for an event",
    "search_presets__stores_near_you": "Stores Near You",
    "search_presets__friday_night_magic": "Friday Night Magic",
    "search_presets__dungeons_and_dragons": "Dungeons & Dragons",
    "search_presets__magic_premier_events": "Magic Premier Events",
    "header__feedback": "反馈",
    "header__find_events": "查找赛事",
    "header__title": "店家与赛事搜寻器",
    "header__classic_link": "Locator Classic",
    "header__search__near": "靠近：",
    "header__search__filter": "filter",
    "header__search__types__magic_events": "Magic Events",
    "header__search__types__magic_premier_events": "Magic Premier Events",
    "header__search__types__dnd": "D&D Events",
    "header__search__distance": "距离",
    "header__search__types__stores": "Stores",
    "header__search__distance__miles": "英里",
    "header__search__distance__kilometers": "km",
    "header__search__distance__unlimited": "unlimited",
    "header__search": "Search",
    "header__search__types__magic_premier_events_mobile": "Premier Events",
    "header__search__types__magic_events_mobile": "Magic",
    "header__search__types__dnd_mobile": "D&D",
    "header__search__types__stores_mobile": "Stores",
    "header__search__types__magic_events_desktop": "Magic Events",
    "header__search__types__magic_premier_events_desktop": "Premier Events",
    "header__search__types__dnd_desktop": "D&D Events",
    "header__search__types__stores_desktop": "Stores",
    "header__banner": "We have updated our <a target=\"_blank\" href=\"https://company.wizards.com/tou\">terms of use</a> and <a target=\"_blank\" href=\"https://company.wizards.com/policy\">privacy policy</a>. Click the links to learn more.",
    "store_details": "Store Details",
    "tag_text__two_headed_giant": "Magic with a partner",
    "tag_text__booster_draft": "Magic with cards selected from a booster",
    "tag_text__commander": "100-card Magic deck fun",
    "tag_text__legacy": "Build your deck out of cards from every Magic set",
    "tag_text__modern": "Play with many Magic sets",
    "tag_text__multiplayer": "Play Magic against many opponents",
    "tag_text__sealed_deck": "Play Magic with boosters provided to you",
    "tag_text__standard": "Play with recent Magic sets",
    "tag_text__pauper": "Play with only common Magic cards",
    "footer__brands__heading": "品牌",
    "footer__cities__heading": "Events in Other Cities",
    "footer__company__about": "关于",
    "footer__company__careers": "招聘信息",
    "footer__company__heading": "公司",
    "footer__company__support": "支持",
    "footer__company__wpn": "Wizards Play Network",
    "footer__copyrights": "© 1993-2024，由孩之宝公司的子公司：威世智有限公司所有。保留所有权利。",
    "footer__find__articles": "文章",
    "footer__find__club_support": "俱乐部支持",
    "footer__find__convention_support": "展会支持",
    "footer__find__digital_books": "电子书",
    "footer__find__formats": "赛制",
    "footer__find__forums": "论坛",
    "footer__find__heading": "常用",
    "footer__find__military_support": "军队支持",
    "footer__find__rules": "规则",
    "footer__socials__connect": "Connect with Wizards",
    "footer__socials__connect2": "on social media",
    "footer__socials__heading": "社交",
    "footer__utils__code_of_conduct": "行为准则",
    "footer__utils__cookies": "Cookies",
    "footer__utils__customer_support": "客户支持",
    "footer__utils__fan_content_policy": "爱好者内容方针",
    "footer__utils__privacy_policy": "隐私政策",
    "footer__utils__terms": "条款",
    "footer__utils__donotsell": "请勿出售或共享我的个人信息",
    "footer__privacy_banner__start_date": "01-07-2023",
    "footer__privacy_banner__days": "30",
    "footer__privacy_banner__message": "We have updated our <a href=\"https://company.wizards.com/en/legal/wizards-coasts-privacy-policy\">privacy policy</a>. Click the link to learn more.",
    "cookies__page__title": "What is a cookie?",
    "cookies__page__describe_cookies": "Cookies are small text files that are placed on your device by apps and websites that you visit. Cookies are widely used in order to make websites work, fulfil your preferences, and to provide information to the owners of websites. The table below explains the cookies we use and why.",
    "cookies__page__table_header__cookie_name": "Cookie Name",
    "cookies__page__table_header__domain": "Domain",
    "cookies__page__table_header__description": "Description",
    "cookies__description__gat": "Used to throttle request rate. if Google Analytics is deployed via Google Tag manager, this cookie will be named _dc_gtm_15020098",
    "cookies__description__gid": "Used to distinguish users.",
    "cookies__description__ga": "Used to distinguish users.",
    "cookies__description__cookie_agree": "This cookie is set as part of cookie notification on the Event Locator web site.",
    "cookies__description__aec": "These cookies prevent malicious sites from acting on behalf of a user without that user’s knowledge",
    "cookies__description__nid": "The “NID” cookie is used to play YouTube videos embedded on the website.",
    "cookies__description__enid": "This cookie is used by Google to remember your preferences and other information.",
    "cookies__description__doubleclick": "These cookies set by a third party (DoubleClick) and are used for serving targeted advertisements that are relevant to you across the web.",
    "cookies__banner__accept": "OK, I agree",
    "cookies__banner__decline": "No, thanks",
    "cookies__banner__copy": "We use necessary cookies to allow our site to function correctly and collect anonymous session data. Necessary cookies can be opted out through your browser settings. We also use optional cookies to personalize content and ads, provide social medial features and analyze web traffic. By clicking “OK, I agree,” you consent to optional cookies.  (<a href=\"{link}\">Learn more about cookies.</a>)",
    "event_content__description__booster_draft": "<p>你想在公平竞争的环境中进行游戏同时又能体验新牌张吗？那么类似补充包轮抽的限制赛可能会适合你。和构组赛制不同，不需带着一副精心构组好的套牌去比赛，作为比赛的一部分，限制赛允许你使用新牌张构组一副套牌。</p><p>在补充包轮抽开始时，每位牌手打开一个补充包并抽取一张牌。（不要让其他牌手看到你抽取的卡牌！）然后每个人把他们补充包中剩下的牌向左传递，每位牌手从刚接到的补充包中抽取一张牌，其后继续传递。如此进行下去，直到所有的牌都选完为止。然后，每位牌手打开第二包补充包，但这次要向右传；在所有的牌张都被抽取之后，你再次向左传递第三包。</p>",
    "event_content__description__dnd": "<p>龙与地下城（D&D）角色扮演游戏讲述了发生在有关剑与魔法的世界中的故事。它拥有一些童年的虚构游戏的元素。和那些游戏一样，D&D由想象力驱动。它需要玩家想象夜晚处于风暴中的巨大城堡以及在此场景中奇幻冒险者如何应付挑战。</p><p>和虚构游戏不同的是，D&D会提供故事的架构，以此决定冒险者行动的后果。玩家通过掷骰子来决定其攻击是否命中或其冒险者是否能够攀上峭壁，以及其能否躲开魔法闪电箭的攻击或完成某些危险的任务。一切皆有可能，但是骰子会让某些结果的出现几率更高。</p><p>在龙与地下城游戏中，每位玩家都要创建一位冒险者（也称为角色），然后和其他冒险者组队（和朋友一起玩）。通过相互合作，这支队伍要探索幽暗的地下城、被毁的城市、闹鬼的城堡、丛林深处的失落神庙或神秘山中满是熔岩的洞穴。冒险者要解开谜题，与其他角色交谈，和奇幻怪物作战，并且找到惊人的魔法物品和其他珍宝。</p>",
    "event_content__description__magic": "无论你从未玩过集换式卡牌游戏，还是您的橱柜里放满了卡牌游戏，万智牌都能为你带来欢乐。在线上或线下体验紧张刺激的法师对决。探索30年来的丰富故事、角色和插画。前往当地游戏店，通过万智牌赛事结交新朋友。你可以通过多种方式成为万智牌社群的一份子，我们也非常欢迎你的加入！",
    "event_content__description__modern": "<p>Modern is a constructed format and therefore adheres to the following constructed rules:</p><p>Minimum of sixty cards No maximum deck size, as long as you can shuffle your deck unassisted Up to fifteen cards in your sideboard, if used With the exception of basic lands (Plains, Island, Swamp, Mountain, and Forest), your combined deck and sideboard may not contain more than four of any individual card, counted by its English card title equivalent.</p><p>Note: The five snow-covered lands from the Ice Age block—Snow-Covered Plains, Snow-Covered Island, Snow-Covered Swamp, Snow-Covered Mountain, and Snow-Covered Forest—are also basic lands. Snow-covered lands are only permitted in formats that allow expansions from the Ice Age block.</p>",
    "event_content__description__standard": "Standard is a dynamic format where you build decks and play using cards in your collection from recently released Magic sets. Evolving gameplay and fresh strategies make it one of the most fun and popular ways to play Magic.",
    "event_content__format": "赛制：补充包轮抽:",
    "event_content__format__modern": "Modern",
    "event_content__format__standard": "Standard",
    "event_content__format__booster_draft": "Booster Draft",
    "footer__utils__privacy_settings": "您的隐私选择"
  }
}
